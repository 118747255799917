$indigo: #845aff;
$white: #ffffff;
$dark: #080e10;
$green: #00dc93;
$gradient: linear-gradient(203deg, rgba(192, 90, 255, 1) 0%, rgba(67, 39, 158, 1) 100%);
$gradient2: linear-gradient(250deg, rgba(255, 85, 219, 1) 24%, rgba(52, 108, 255, 1) 100%);

.green {
  color: $green;
}
.indigo {
  color: $indigo;
}
