header {
  padding: $s 0;
  border-bottom: 1px solid rgba($white, 0.2);
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  z-index: 999;
  .container {
    @include flex(center, space-between, none);
    nav {
      a {
        margin: 0 $xl-2;
      }
    }
  }
}
.activeLink {
  position: relative;
  color: $green;
  &::after {
    content: "";
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $green;
  }
}
.navLink:hover {
  color: $green;
  text-decoration: none;
}

.bars {
  display: none;
}

@media screen and (max-width: 965px) {
  header {
    .bars {
      display: block;
    }
    .menu {
      display: none;
    }
    .openMenu {
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      height: 40vh;
      padding: 20px;
      background: $dark;
      display: flex;
      flex-direction: column;
      z-index: 999;
      a {
        width: 100%;
        display: block;
        display: flex;
        margin-bottom: 30px !important;
      }
    }
    .button-primary {
      display: none;
    }
  }
  .activeLink {
    &::after {
      display: none;
    }
  }
}
